import { PostMetadata } from "./PostMetadata";
import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { TwitterIcon, LinkedInIcon } from "@xolvio/xolvio-ui";
import { defaultFooterProps } from "../../../pages-content/homepage";
import sizes from "../sizes";
import { spacing } from "@xolvio/xolvio-ui";

export const PostMetadataWithIcons = ({
  src,
  name,
  dateString,
  readingTime,
  twitter,
  linkedIn
}) => (
  <Wrapper>
    <PostMetadata
      src={src}
      name={name}
      dateString={dateString}
      readingTime={readingTime}
      bigProfile
    />
    {twitter && (
      <a href={`https://www.twitter.com/${twitter}`} target={"_blank"}>
        <TwitterIcon />
      </a>
    )}
    {linkedIn && (
      <a href={linkedIn} target={"_blank"}>
        <LinkedInIcon />
      </a>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${spacing.mobile.padding.default}px;
  max-width: ${sizes.maxContentWidth}px;

  > *:first-child {
    align-self: flex-start;
    flex-grow: 2;
  }
  > *:not(:first-child) {
    margin-left: 24px;
  }
`;
