export default {
  buttonHeight: 36,
  bodyButtonHeight: 48,
  bodyFontHeight: 18,
  iconHeight: 24,
  logoHeight: 40,
  buttonRadius: 2,
  buttonFontHeight: 14,
  actionItemHeight: 68,
  headerHeight: 68,
  menuWidth: 300,
  navMenuWidth: 88,
  // contentMenuWidth: 288,
  contentMenuWidth: 300,
  inputPadding: 16,

  // new
  maxContentWidth: 700
};
