import React, { useCallback, useEffect, useRef, useState } from "react";
import { colors, TextBody, TwitterIcon } from "@xolvio/xolvio-ui";
import styled from "styled-components";

function isCodeDescendant(node) {
  try {
    while (node.parentNode && node.parentNode.tagName !== "BODY") {
      const tagName = node.parentNode.tagName;
      if (tagName === "CODE" || tagName === "PRE") {
        return true;
      }
      node = node.parentNode;
    }
  } catch {
    return false;
  }
  return false;
}

// Hook
export const useSelectToTweet = () => {
  const isClient = typeof window === "object";

  const [selection, setSelection] = useState(null);
  const iconId = "twitter-share-icon";
  const ref = useRef(null);
  const containerRef = useCallback(node => {
    ref.current = node;
  }, []);

  const onClick = () => {
    if (!selection) {
      return;
    }
    const url =
      "https://twitter.com/share?url=" +
      encodeURIComponent(window.location.href.split("?")[0]) +
      "&text=" +
      encodeURIComponent(selection.text);
    window.open(url);
    setSelection(null);
  };

  const hideTwitterPrompt = event => {
    const element = document.getElementById(iconId);
    if (
      element &&
      (event.target === element || element.contains(event.target))
    ) {
      event.preventDefault();
      onClick();
    } else {
      setSelection(null);
    }
  };

  useEffect(() => {
    const container = ref.current;

    if (!isClient || !container) {
      return undefined;
    }

    const getSelectionText = () => {
      if (window?.getSelection()?.toString().length > 0) {
        return window.getSelection().toString();
      }
      return "";
    };

    function handleSelect() {
      const text = getSelectionText();
      const sel = window.getSelection();

      if (isCodeDescendant(sel.focusNode)) {
        return;
      }

      const range = document.createRange();
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.setEnd(sel.focusNode, sel.focusOffset);

      const rects = sel.getRangeAt(0).getClientRects();
      range.detach();
      const n = rects.length - 1;

      if (text?.length) {
        setSelection({
          text,
          top: Math.round(rects[n].top) + window.pageYOffset,
          left: Math.round(rects[n].right) + 10
        });
      }
    }

    ref.current.addEventListener("mouseup", handleSelect);
    window.addEventListener("mousedown", hideTwitterPrompt);

    return () => {
      ref.current.removeEventListener("mouseup", handleSelect);
      window.removeEventListener("mousedown", hideTwitterPrompt);
    };
  }, [ref]);

  const renderTweet = () => {
    if (!selection) return null;

    const { top = 0, left = 0 } = selection;

    return (
      <IconWrapper
        id={iconId}
        style={{
          top,
          left
        }}
        onClick={onClick}
      >
        <TwitterIcon width="28" height="28" title="Click to share on twitter" />
        <TextBody
          fontSize={16}
          style={{ margin: "8px", color: colors.TextBlack }}
        >
          Tweet this
        </TextBody>
      </IconWrapper>
    );
  };

  return { selection, renderTweet, containerRef };
};

const IconWrapper = styled.div`
  border-radius: 2px;
  padding: 0 8px;
  position: absolute;
  cursor: pointer;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(-5px 5px 5px rgb(29, 161, 242, 0.7));
  border: 0px solid rgb(29, 161, 242, 0.4);

  svg {
    color: rgb(29, 161, 242);
    transition: transform 0.4s;
    fill: currentColor;
  }
  :hover svg {
    transition: transform 0.25s ease-in-out;
    transform: rotate(10deg);
  }
  svg g > * {
    stroke: white;
    stroke-width: 0.5px;
    fill: currentColor;
  }
`;
