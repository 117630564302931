import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

export const SEO = ({ title, description, image, canonicalUrl, url }) => {
  return (
    <Helmet title={title}>
      <meta property="og:type" content="website" />
      <meta name="description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="image" content={image} />
      {url && <meta property="og:url" content={url} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      {canonicalUrl && (
        <link data-rh="true" rel="canonical" href={canonicalUrl} />
      )}
    </Helmet>
  );
};
export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null
};
