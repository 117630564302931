import React from "react";
import { getFormattedDate } from "../getFormattedDate";
import styled from "styled-components";
import colors from "../colors";
import { spacing } from "@xolvio/xolvio-ui";
import blogColors from "../colors";

export function PostMetadata(props) {
  return (
    <Meta>
      <ProfilePic src={props.src} bigProfile={props.bigProfile} />
      <div>
        <MetaName>{props.name}</MetaName>
        <MetaDetails>
          <span>{getFormattedDate(props.dateString)}</span>
          <Dot />
          <span>{props.readingTime} min read</span>
        </MetaDetails>
      </div>
    </Meta>
  );
}

const MetaName = styled.span`
  font-size: 15px;
  margin-bottom: 10px;
`;
const MetaDetails = styled.div`
  color: ${blogColors.subheadingGrey};
  margin-top: 5px;
  font-size: 13px;
`;

const Dot = styled.span`
  margin: 5px;
  :after {
    content: "‧";}
  }
`;
const Meta = styled.div`
  display: flex;
  align-items: center;
`;

const ProfilePic = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  margin-top: 3px;
  background-color: ${colors.lightGrey};
  border-radius: 50%;
  height: ${props => (props.bigProfile ? 48 : 36)}px;
  width: ${props => (props.bigProfile ? 48 : 36)}px;
  margin-right: ${spacing.mobile.padding.default / 2}px;
`;
