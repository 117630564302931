const primary = "#56BF98";
const secondary = "#B7BCBA";
const tertiary = "#D8F6E9";
const black = "#3c3f3e";
const grey = "#B7BCBA";
const mediumGrey = "#D8DCDB";
const lightGrey = "#EDEFEE";
const white = "#FFFFFF";
const accentPurple = "#9793FE";
const accentRed = "#CA1301";
const accentOrange = "#FFC676";
const textLight = "#A4AAA8";
const textMedium = "#6B726F";
const textDark = "rgba(0, 0, 0, 0.84)";

const inputBorder = mediumGrey;
const inputDivider = lightGrey;
const inputText = black;
const inputPlaceholderText = textLight;

const inputFocusedBorder = "#81D8B7";
const inputErrorBorder = accentRed;
const subheadingGrey = "#757575";
export default {
  primary,
  secondary,
  tertiary,
  black,
  grey,
  mediumGrey,
  lightGrey,
  white,
  accentPurple,
  accentRed,
  accentOrange,
  textLight,
  textMedium,
  textDark,
  inputBorder,
  inputDivider,
  inputText,
  inputPlaceholderText,
  inputFocusedBorder,
  inputErrorBorder,
  subheadingGrey
};
