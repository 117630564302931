import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { device } from "@xolvio/xolvio-ui";
import { PostTile } from "./PostTile";
import { spacing } from "@xolvio/xolvio-ui";
import { getPostUrl } from "../../../../getPageUrl";
import { filterPosts } from "../filterPosts";

export const Posts = ({ small, skip }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const posts = data.allGhostPost.edges;
        return <PostTiles posts={posts} small={small} skip={skip} />;
      }}
    />
  );
};

export const PostTiles = ({ small, posts, skip, isPreview = false }) => {
  const displayedPosts = filterPosts({
    small,
    posts,
    skip,
  });

  return (
    <>
      <BlogLayout
        className={`modulo-${displayedPosts.length % 3}`}
        small={small}
      >
        {displayedPosts.map((post) => {
          return (
            <PostTile
              {...post.node}
              url={getPostUrl(post.node, isPreview)}
              key={post.node.slug}
            />
          );
        })}
      </BlogLayout>
    </>
  );
};

export const BlogLayout = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${spacing.mobile.padding.default}px;
  }

  @media ${device.tabletVertical} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${spacing.mobile.padding.default}px;

    ${(props) =>
      !props.small
        ? `
        > *:nth-child(1),
        > *:nth-child(2n):nth-last-child(1) {
          grid-column: 1 / span 2;
     
          > a > div[src] {
            height: 240px;
          }
        }
    `
        : ""}
  }

  @media ${device.desktop} {
    ${(props) =>
      props.small
        ? `
         display: grid;
         grid-template-columns: 1fr 1fr 1fr;
      `
        : `
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        > * {
          width: calc(100% / 3 - 2 * ${spacing.mobile.padding.default}px / 3);
          margin-bottom: ${3 * spacing.mobile.padding.default}px;
         
          > a > div[src] {
            height: 170px;
          }
        }
      `}
`;

const query = graphql`
  query {
    allGhostPost(
      filter: {
        visibility: { eq: "public" }
        primary_tag: { name: { eq: "career" } }
      }
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          tags {
            name
          }
          id
          title
          url
          slug
          published_at
          visibility
          excerpt
          reading_time
          feature_image
          authors {
            name
            profile_image
          }
        }
      }
    }
  }
`;

export default Posts;
