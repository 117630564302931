import Image from "gatsby-image";
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { BlogLayout } from "../BlogLayout";
import { LayoutDefault } from "../../LayoutDefault";
import { spacing, colors } from "@xolvio/xolvio-ui";
import blogColors from "../colors";
import sizes from "../sizes";
import { device } from "@xolvio/xolvio-ui";
import { PostMetadataWithIcons } from "./PostMetadataWithIcons";
import Posts from "../Posts/Posts";
import { CenteredContentWrapper } from "@xolvio/xolvio-ui";
import SEO from "../SEO";
import { getPostUrl } from "../../../../getPageUrl";
import { useSelectToTweet } from "../useSelectToTweet";
import { getMediumForUTM } from "../../../../getMediumForUTM";

export const Post = ({ data, location }) => {
  const post = data.ghostPost;
  const { renderTweet, containerRef: tweetSelectionContainerRef } =
    useSelectToTweet();
  const previewImageUrl = post.localFeatureImage
    ? `${data.site.siteMetadata.siteUrl}${post.localFeatureImage.childImageSharp.fixed.src}`
    : post.feature_image;
  return (
    <LayoutDefault>
      <SEO
        description={post.excerpt}
        image={previewImageUrl}
        title={post.title}
        canonicalUrl={post.canonical_url}
        pathname={post.slug}
        url={getPostUrl(post)}
      />
      <BlogLayout location={location}>
        <Flex>
          <FeatureImageWrapper>
            {post.localFeatureImage ? (
              <Image
                fluid={post.localFeatureImage.childImageSharp.fluid}
                durationFadeIn={500}
              />
            ) : (
              <img src={post.feature_image} width={"100%"} />
            )}
          </FeatureImageWrapper>

          <div>
            <Title>{post.title}</Title>

            <PostMetadataWithIcons
              src={post.authors[0].profile_image}
              name={post.authors[0].name}
              twitter={post.authors[0].twitter}
              linkedIn={post.authors[0].website}
              dateString={post.published_at}
              readingTime={post.reading_time}
            />
          </div>
        </Flex>
        <div ref={tweetSelectionContainerRef}>
          <Content dangerouslySetInnerHTML={{ __html: post.html }} />
          {renderTweet()}
        </div>

        <Content></Content>

        <CenteredContentWrapper style={{ marginBottom: 48 }}>
          <Posts
            small
            skip={post.slug}
            renderDevNotes={getMediumForUTM(post) === "dev-notes"}
          />
        </CenteredContentWrapper>
      </BlogLayout>
    </LayoutDefault>
  );
};

export default Post;

// see here for available fields: https://ghost.org/docs/api/v3/content/#posts
export const postQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      localFeatureImage {
        childImageSharp {
          fluid(quality: 91, maxWidth: 2560) {
            ...GatsbyImageSharpFluid_withWebp
          }
          fixed(width: 900) {
            src
          }
        }
      }
      html
      published_at
      canonical_url
      reading_time
      excerpt
      tags {
        name
      }
      authors {
        name
        profile_image
        twitter
        website
      }
    }
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 80px;
  @media ${device.tablet} {
    margin-top: initial;
    flex-direction: column;
  }
`;
const Title = styled.h1`
  max-width: ${sizes.maxContentWidth}px;
  margin: 0 auto 24px;
  line-height: 40px;
  padding: 0 ${spacing.mobile.padding.default}px;
  font-family: "Merriweather Light", serif;
  font-size: 26px;
  font-weight: normal !important;
  @media ${device.tablet} {
    font-size: 35px;
    line-height: 43px;
  }
`;

const FeatureImageWrapper = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.mobile.padding.default}px;
  margin-bottom: -12px;

  @media ${device.tablet} {
    width: 700px;
    margin-top: unset;
    margin-bottom: ${1.5 * spacing.mobile.padding.default}px;
  }
`;

const Content = styled.section`
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: auto;
  font-family: unset;
  padding: ${spacing.mobile.padding.default}px;
  padding-top: ${spacing.mobile.padding.default / 2}px;

  * ::-moz-selection {
    /* Code for Firefox */
    color: white !important;
    text-shadow: none !important;
    background: ${colors.AcceptanceGreen} !important;
  }

  * ::selection {
    color: white !important;
    text-shadow: none !important;
    background: ${colors.AcceptanceGreen} !important;
  }

  > *:first-child {
    margin-top: 24px;
    padding-top: 0;
    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  > * {
    display: block;
    max-width: calc(100%);
    margin: ${spacing.mobile.padding.default}px auto;

    @media ${device.tablet} {
      max-width: ${sizes.maxContentWidth}px;
    }
  }

  // text
  p,
  a,
  ul,
  li,
  em,
  blockquote {
    color: ${blogColors.textDark};
    font-family: "Merriweather Light";
    font-weight: normal;
    line-height: 1.8;
    font-size: 16px;
    letter-spacing: 0.063px;

    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  .gist {
    width: 100%;
    font-family: unset;
  }

  .gist * {
    font-family: inherit;
  }

  .gist a {
    font-family: inherit;
    font-size: inherit;
    box-shadow: none;
  }

  q {
    box-shadow: inset 3px 0 0 0 rgba(41, 41, 41, 1);
    padding-left: 24px;
    display: block;
    font-style: italic;
    font-size: 21px;
    font-family: "Merriweather Light";
    margin-top: 40px;
    margin-bottom: 40px;

    * {
      font-family: "Merriweather Light";
    }

    :before,
    :after {
      content: "";
    }
  }

  ol,
  ul {
    @media ${device.mobile} {
      padding-left: 12px;
    }
  }

  li {
    margin: 16px;
  }

  a,
  a > * {
    text-decoration: none;
    color: ${colors.AcceptanceGreen};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "HK Grotesk", Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  // // first post line highlight
  h3:first-child {
    font-weight: 500;
    font-size: 1.3em;
    color: ${blogColors.textLight};
  }

  h3 {
    margin-top: ${1.2 * spacing.mobile.padding.default}px;
    font-size: 19px;
    margin-bottom: 8px;
    margin-bottom: -15px;

    @media ${device.tablet} {
      font-size: 24px;
    }
  }

  figure + h2 {
    margin-top: 24px;
  }
  h2 {
    font-size: 24px;
    margin-top: ${spacing.mobile.padding.default}px;

    margin-bottom: -15px;

    @media ${device.tablet} {
      font-size: 32px;
    }
  }

  blockquote {
    max-width: 650px;
    color: ${blogColors.textLight};
    font-size: 24px;
    line-height: 38px;
    padding-left: ${spacing.mobile.padding.default}px;
    margin-top: 36px;
    margin-bottom: 32px;

    @media ${device.tablet} {
      margin-top: 70px;
      margin-bottom: 50px;
    }
  }
  strong {
    font-family: inherit;
    font-weight: bold;
  }
  blockquote > * {
    font-size: 1em;
    font-family: inherit;
    color: inherit;
    font-style: unset;
  }
  hr {
    margin-top: ${2 * spacing.mobile.padding.default}px;
    margin-bottom: ${3 * spacing.mobile.padding.default}px;
    border: none;
    :after {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      font-size: 0.75em;
      content: "\\2022\\2003\\2003\\2022\\2003\\2003\\2022";
    }
  }

  figure:not(.kg-bookmark-card) {
    padding: ${(1 / 3) * spacing.mobile.padding.default}px 0;
    margin: ${spacing.mobile.padding.default}px auto;
    width: 100% !important;
    max-width: 100% !important;
    position: relative;

    @media ${device.tablet} {
      padding: ${spacing.mobile.padding.default}px 0;
    }

    img {
      max-width: 100%;
      margin: auto;
      display: block;
    }

    figcaption {
      margin-top: ${spacing.mobile.padding.default / 2}px;
      text-align: center;
      color: ${blogColors.textMedium};
      font-weight: 500;
      font-size: 14px;
    }
  }

  iframe {
    display: block;
    margin: ${2 * spacing.mobile.padding.default}px auto;
    width: 1190px !important;
    height: 670px !important;
    max-width: 100%;
    max-height: 200px;
    @media ${device.tabletVertical} {
      max-height: 500px;
    }

    @media ${device.desktop} {
      max-height: 100%;
    }
  }

  iframe,
  figure:not(.kg-bookmark-card) {
    max-width: 100%;
    @media ${device.tabletVertical} {
    }
  }

  //code
  .gist {
    font-family: unset;
  }

  .gist * {
    font-family: inherit;
  }

  gist a {
    box-shadow: none;
  }

  pre {
    margin: auto;
    margin-bottom: 1em;
    max-width: 654px;
  }
  pre,
  pre .operator {
    background: ${blogColors.lightGrey};
  }

  pre,
  pre * {
    font-size: 14px;
    font-family: Consolas, Monaco, "Andale Mono", "Lucida Console", monospace;
  }
  pre code:not([class*="language-"]) {
    font-style: italic;
    font-size: 14px;
  }
  pre,
  pre code[class*="language-"] {
    padding: 16px;
    margin-top: 30px;
    overflow-x: scroll;
  }

  pre[class*="language-"] {
    margin-left: auto;
    margin-right: auto;
  }

  pre code[class*="language-"] {
    padding: 0;
    font-family: unset;
  }

  // styles for classes created by ghost.io

  .kg-image-card {
    width: ${sizes.maxContentWidth}px;
  }

  .kg-card.kg-image-card.kg-width-wide {
    width: ${1.5 * sizes.maxContentWidth}px;
    max-height: 100%;

    @media ${device.desktop} {
      max-width: ${1.5 * sizes.maxContentWidth}px;
    }

    @media ${device.wide} {
      max-height: 100%;
    }
  }

  .kg-width-full {
    max-width: calc(100% + 2 * ${spacing.mobile.padding.default}px);
    width: calc(100% + 2 * ${spacing.mobile.padding.default}px);
    position: relative;
    left: -${spacing.mobile.padding.default}px;
  }

  .kg-bookmark-card {
    width: 100%;
  }
  .kg-card + .kg-bookmark-card {
    margin-top: 0;
  }
  //.post-full-content .kg-bookmark-container {
  .kg-bookmark-container {
    display: flex;
    min-height: 148px;
    color: var(--darkgrey);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none;
    border-radius: 3px;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
  }
  //.post-full-content .kg-bookmark-container:hover {
  .kg-bookmark-container:hover {
    color: var(--darkgrey);
    text-decoration: none;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
  }
  .kg-bookmark-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
  }
  .kg-bookmark-title {
    //color: color(var(--midgrey) l(-30%));
    color: ${colors.textDark};
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 600;
    transition: color 0.2s ease-in-out;
  }
  .post-full-content .kg-bookmark-container:hover .kg-bookmark-title {
    color: ${colors.textDark};
    //color: var(--blue);
  }
  .kg-bookmark-description {
    display: -webkit-box;
    overflow-y: hidden;
    margin-top: 12px;
    max-height: 48px;
    //color: color(var(--midgrey) l(-10%));
    color: ${colors.textDark};
    font-size: 14px;
    //line-height: 1.5em;
    font-weight: 400;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .kg-bookmark-thumbnail {
    position: relative;
    min-width: 33%;
    max-height: 100%;
  }
  .kg-bookmark-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 3px 3px 0;

    object-fit: cover;
  }
  .kg-bookmark-metadata {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 14px;
    color: color(var(--midgrey) l(-10%));
    font-size: 16px;
    font-weight: 400;
  }
  //.post-full-content .kg-bookmark-icon {
  .kg-bookmark-icon {
    margin-right: 8px;
    width: 22px;
    height: 22px;
  }
  .kg-bookmark-author {
    line-height: 1.5em;
  }
  .kg-bookmark-author:after {
    content: "•";
    margin: 0 6px;
  }
  .kg-bookmark-publisher {
    overflow: hidden;
    max-width: 240px;
    line-height: 1.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kg-bookmark-title,
  .kg-bookmark-description,
  .kg-bookmark-metadata {
    color: ${blogColors.textDark};
  }
`;
