import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import "../../reset.css";
import "../../fonts-blog.css";
import("prismjs/themes/prism.css");
import { device } from "@xolvio/xolvio-ui";
import Prism from "prismjs";

export const BlogLayout = ({ children }) => {
  useEffect(() => {
    Prism.highlightAll();
  });
  useEffect(() => {
    // https://codingreflections.com/hide-header-on-scroll-down/
    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var direction = 0;
    var prevDirection = 0;

    var header = document.getElementById("site-menu");

    var checkScroll = function() {
      /*
       ** Find the direction of scroll
       ** 0 - initial, 1 - up, 2 - down
       */

      curScroll = w.scrollY || doc.scrollTop;
      if (curScroll > prevScroll) {
        //scrolled up
        direction = 2;
      } else if (curScroll < prevScroll) {
        //scrolled down
        direction = 1;
      }

      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll);
      }

      prevScroll = curScroll;
    };

    var toggleHeader = function(direction, curScroll) {
      if (direction === 2 && curScroll > 52) {
        //replace 52 with the height of your header in px

        header.classList.add("hide");
        prevDirection = direction;
      } else if (direction === 1) {
        header.classList.remove("hide");
        prevDirection = direction;
      }
    };

    window.addEventListener("scroll", checkScroll);
  }, []);
  return (
    <>
      <ExtraHederStyles />
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

// const ExtraHederStyles = styled.createGlobalStyle`
const ExtraHederStyles = createGlobalStyle`
@media ${device.mobile} {
  #site-menu {
    position: fixed;
    height: 52px;
    background: #fff;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: all 0.3s ease;
    box-shadow: 0 1px 25px rgba(0, 0, 0, 0.1);
    visibility: visible;
    & * {
          transition: all 0.3s ease;
    }
  }
  #site-menu.hide,
  #site-menu.hide * {
    top: -100px !important;
  }
  }
`;
const ContentContainer = styled.article`
  display: block;
  width: 100%;
`;
